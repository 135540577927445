import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import ManageUsers from './pages/ManageUsers';
import RegisteredUser from './pages/RegisterUser';
import Plans from './pages/Plans';
import Login from './pages/Login';
import PaymentsPage from './pages/Payments';
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') !== null);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="app-container">
      {isAuthenticated && <Sidebar />}
      <main>
        <Routes>
          {/* Public Route for Login */}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/registered-users"
            element={isAuthenticated ? <RegisteredUser /> : <Navigate to="/login" />}
          />
          <Route
            path="/plans"
            element={isAuthenticated ? <Plans /> : <Navigate to="/login" />}
          />
        <Route
            path="/payments"
            element={isAuthenticated ? <PaymentsPage /> : <Navigate to="/login" />}
          />
          <Route
            path="/manage-users"
            element={isAuthenticated ? <ManageUsers /> : <Navigate to="/login" />}
          />
        </Routes>
      </main>
    </div>
  );
}

export default App;
