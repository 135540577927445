import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../styles/PaymentsPage.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

function PaymentPage() {
  const [payments, setPayments] = useState([]);
  const [filters, setFilters] = useState({
    gymId: "",
    startDate: "",
    endDate: "",
  });
  const [filterInputs, setFilterInputs] = useState({
    gymId: "",
    startDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [collectorSummary, setCollectorSummary] = useState({});
  const [monthlySummary, setMonthlySummary] = useState({
    totalCollection: 0,
    tariCollection: 0,
    labbiCollection: 0,
  });

  const fetchPayments = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const params = {};
      if (filters.gymId) params.gym_id = filters.gymId;
      if (filters.startDate) params.start_date = filters.startDate;
      if (filters.endDate) params.end_date = filters.endDate;

      const response = await axios.get("http://38.242.142.120:5000/get_payments", {
        headers: { Authorization: token },
        params,
      });

      const payments = response.data.payments || [];
      setPayments(payments);

      if (payments.length > 0) setError("");

      let tariCollection = 0;
      let labbiCollection = 0;

      payments.forEach((payment) => {
        if (payment.fee_collector_name === "Tari") tariCollection += payment.payment_amount;
        if (payment.fee_collector_name === "Labbi") labbiCollection += payment.payment_amount;
      });

      setCollectorSummary({ Tari: tariCollection, Labbi: labbiCollection });
    } catch (err) {
      setPayments([]);
      setError("Failed to fetch payments.");
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const fetchMonthlyCollections = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("http://38.242.142.120:5000/monthly_collections", {
        headers: { Authorization: token },
      });

      const { total_tari_collections, total_labbi_collections } = response.data;

      setMonthlySummary({
        totalCollection: total_tari_collections + total_labbi_collections,
        tariCollection: total_tari_collections,
        labbiCollection: total_labbi_collections,
      });
    } catch (err) {
      setError("Failed to fetch monthly collections.");
    }
  }, []);

  useEffect(() => {
    fetchPayments();
    fetchMonthlyCollections();
  }, [fetchPayments, fetchMonthlyCollections]);

  const handleInputChange = (field) => (e) => {
    setFilterInputs({ ...filterInputs, [field]: e.target.value });
  };

  const applyFilters = () => {
    if (filterInputs.startDate && filterInputs.endDate && new Date(filterInputs.startDate) > new Date(filterInputs.endDate)) {
      setError("Start date cannot be later than end date.");
      return;
    }
    setError("");
    setFilters(filterInputs);
  };

  const resetFilters = () => {
    setFilterInputs({ gymId: "", startDate: "", endDate: "" });
    setFilters({ gymId: "", startDate: "", endDate: "" });
    setError("");
  };

  return (
    <div className="payment-page-container">
      <h1 className="payment-page-title">Payments</h1>

      <div className="monthly-summary-container">
        <h2 className="summary-title green-summary">Monthly Collection</h2>
        <ul className="summary-list">
          <li className="summary-item">
            <span className="collector-name">Total Collection:</span> ₹{monthlySummary.totalCollection}
          </li>
          <li className="summary-item">
            <span className="collector-name">Tari Collection:</span> ₹{monthlySummary.tariCollection}
          </li>
          <li className="summary-item">
            <span className="collector-name">Labbi Collection:</span> ₹{monthlySummary.labbiCollection}
          </li>
        </ul>
      </div>

      <div className="summary-container">
        <h2 className="summary-title">Total Fee Collection</h2>
        <ul className="summary-list">
          {Object.entries(collectorSummary).map(([collector, amount], index) => (
            <li key={index} className="summary-item">
              <span className="collector-name">{collector}:</span> ₹{amount}
            </li>
          ))}
        </ul>
      </div>

      <div className="filter-container">
        <input
          type="text"
          placeholder="Gym ID"
          value={filterInputs.gymId}
          onChange={handleInputChange("gymId")}
          className="filter-input"
        />
        <input
          type="date"
          value={filterInputs.startDate}
          onChange={handleInputChange("startDate")}
          className="filter-input"
        />
        <input
          type="date"
          value={filterInputs.endDate}
          onChange={handleInputChange("endDate")}
          className="filter-input"
        />
        <button onClick={applyFilters} className="apply-filters-button">
          Apply Filters
        </button>
        <button onClick={resetFilters} className="reset-filters-button">
          Reset Filters
        </button>
      </div>

      {error && <p className="error-message">{error}</p>}

      {loading ? (
        <p className="loading-message">Loading payments...</p>
      ) : payments.length > 0 ? (
        <div className="payment-table-container">
          <table className="payment-table">
            <thead>
              <tr>
                <th>Gym ID</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Fee Collector</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <TransitionGroup component="tbody">
              {payments.map((payment, index) => (
                <CSSTransition key={index} timeout={300} classNames="fade">
                  <tr>
                    <td>{payment.gym_id}</td>
                    <td>{payment.name}</td>
                    <td>{payment.payment_amount}</td>
                    <td>{payment.fee_collector_name}</td>
                    <td>{new Date(payment.payment_date).toLocaleString()}</td>
                  </tr>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </table>
        </div>
      ) : (
        <p className="no-results-message">No payment records found.</p>
      )}
    </div>
  );
}

export default PaymentPage;
