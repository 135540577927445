import React, { useState, useEffect } from 'react';
import '../styles/Dashboard.css';

function Dashboard() {
  const [showDialog, setShowDialog] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [receivedBy, setReceivedBy] = useState('');
  const [overdueUsers, setOverdueUsers] = useState([]);
  const [totalPendingFee, setTotalPendingFee] = useState(null);
  const [totalRegisteredUsers, setTotalRegisteredUsers] = useState(null);
  const [lastMonthCollections, setLastMonthCollections] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  // Format date to DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Fetch overdue users
  const fetchOverdueUsers = async () => {
    try {
      const response = await fetch('http://38.242.142.120:5000/users_by_status/feepending', {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      const data = await response.json();
      if (response.ok) {
        setOverdueUsers(data);
      } else {
        console.error('Failed to fetch overdue users data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching overdue users:', error);
    }
  };

  // Fetch data for Total Pending Fee, Total Registered Users, and Last Month's Collections
  const fetchDashboardStats = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Token missing. Please log in.');
        return;
      }

      const fetchData = async (url) => {
        const response = await fetch(url, { headers: { Authorization: token } });
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          console.error(`Failed to fetch from ${url}:`, data.error);
          return null;
        }
      };

      const feeData = await fetchData('http://38.242.142.120:5000/total_pending_fee');
      if (feeData) setTotalPendingFee(feeData.total_pending_fee);

      const usersData = await fetchData('http://38.242.142.120:5000/total_registered_users');
      if (usersData) setTotalRegisteredUsers(usersData.total_registered_users);

      const collectionsData = await fetchData('http://38.242.142.120:5000/last_month_collections');
      if (collectionsData) setLastMonthCollections(collectionsData.last_month_collections);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
    fetchOverdueUsers();
  }, []);

  const closeModal = () => {
    setShowDialog(false);
    setIsImageModalOpen(false);
  };

  const openDialog = (user) => {
    setSelectedUser(user);
    setShowDialog(true);
  };

  const openImageModal = (user) => {
    setSelectedUser(user);
    setIsImageModalOpen(true);
  };

  const handlePayment = async () => {
    const paymentAmount = parseFloat(document.querySelector('.fee-input').value);
    const feeCollectorName = receivedBy;

    if (isNaN(paymentAmount) || paymentAmount <= 0) {
      alert('Please enter a valid payment amount.');
      return;
    }

    if (!feeCollectorName) {
      alert('Fee collector name is required.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Token missing. Please log in.');
      return;
    }

    const requestBody = {
      gym_id: selectedUser.gym_id,
      payment_amount: paymentAmount,
      fee_collector_name: feeCollectorName,
    };

    try {
      setIsProcessingPayment(true);

      const response = await fetch('http://38.242.142.120:5000/make_payment', {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.message === 'Payment processed successfully') {
        alert(`Payment of ₹${paymentAmount} successfully processed.`);
        fetchOverdueUsers(); // Refresh overdue users list
        fetchDashboardStats(); // Refresh dashboard stats
        setShowDialog(false);
      } else {
        alert('Error processing payment: ' + (data.error || 'Unknown error.'));
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong. Please try again.');
    } finally {
      setIsProcessingPayment(false);
    }
  };

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>

      <div className="dashboard-stats">
        <div className="stat-card">Total Registered Users: {totalRegisteredUsers}</div>
        <div className="stat-card">Pending Fees: ₹{totalPendingFee}</div>
        <div className="stat-card">Last Month’s Collection: ₹{lastMonthCollections}</div>
      </div>

      <div className="overdue-users">
        <h2>Overdue Users</h2>
        <ul>
          {overdueUsers.map((user) => (
            <li key={user.gym_id} onClick={() => openDialog(user)}>
              <img
                src={`http://38.242.142.120:5000/profile_pics/${user.profile_pic}?token=${localStorage.getItem('token')}`}
                alt={user.name}
                className="user-image"
                onClick={(e) => {
                  e.stopPropagation();
                  openImageModal(user);
                }}
                onError={(e) => (e.target.src = '/path/to/placeholder.png')}
              />
              <div className="user-info">
                <p><strong>Gym ID:</strong> {user.gym_id}</p>
                <p><strong>Name:</strong> {user.name}</p>
                <p><strong>Fee Due:</strong> <span style={{ color: user.due_amount > 0 ? 'red' : 'black' }}>₹{user.due_amount}</span></p>
                <p><strong>Due Date:</strong> {user.due_date ? formatDate(user.due_date) : 'N/A'}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Image Modal */}
      {isImageModalOpen && selectedUser && (
        <div className="image-modal-overlay" onClick={closeModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>X</button>
            <img
              src={`http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token')}`}
              alt="User"
              className="image-modal-avatar"
            />
          </div>
        </div>
      )}

      {/* Payment Dialog */}
      {/* Payment Dialog */}
      {showDialog && selectedUser && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-header">
              <h2>Payment for {selectedUser.name}</h2>
              <button className="close-btn" onClick={closeModal}>
                X
              </button>
            </div>

            <img
              src={`http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token')}`}
              alt={selectedUser.name}
              className="dialog-image"
            />
            <p><strong>Gym ID:</strong> {selectedUser.gym_id}</p>
            <p><strong>Mobile:</strong> {selectedUser.mobile_number}</p>
            <p><strong>Due Amount:</strong> <span style={{ color: selectedUser.due_amount > 0 ? 'red' : 'black' }}>₹{selectedUser.due_amount}</span></p>
            <p><strong>Due Date:</strong> {selectedUser.due_date}</p>
            <p><strong>Village:</strong> {selectedUser.village}</p>

            <input type="number" placeholder="Enter Amount" className="fee-input" />

            <label htmlFor="receivedBy" className="received-by-label">Received By:</label>
            <select
              id="receivedBy"
              className="received-by-select"
              value={receivedBy}
              onChange={(e) => setReceivedBy(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Labbi">Labbi</option>
              <option value="Tari">Tari</option>
            </select>

            <div className="payment-buttons">
            <button 
              onClick={handlePayment} 
              disabled={isProcessingPayment} 
              className={`payment-btn ${isProcessingPayment ? 'disabled' : ''}`}>
              {isProcessingPayment ? 'Processing...' : 'Mark as Paid'}
            </button>
              <button onClick={closeModal} className="close-btn">×</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
