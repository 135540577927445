import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';

function Sidebar() {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Initially assume the user is authenticated
  const navigate = useNavigate();

  // Check if user is logged in (token exists) and set authentication state
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsAuthenticated(false); // If no token, set isAuthenticated to false
      navigate('/login'); // Redirect to login page if not logged in
    } else {
      setIsAuthenticated(true); // If token exists, user is authenticated
    }
  }, [navigate]);

  const handleLogoutClick = () => {
    setShowLogoutPopup(true); // Show the confirmation popup
  };

  const confirmLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await fetch('http://38.242.142.120:5000/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token, // Send token directly
        },
      });

      if (response.ok) {
        // Successfully logged out
        localStorage.removeItem('token'); // Clear the token from local storage
        setIsAuthenticated(false); // Set authentication state to false
        navigate('/login'); // Redirect to login page
      } else {
         // Successfully logged out
         localStorage.removeItem('token'); // Clear the token from local storage
         setIsAuthenticated(false); // Set authentication state to false
         navigate('/login'); // Redirect to login page
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
    setShowLogoutPopup(false); // Close the confirmation popup
  };

  const cancelLogout = () => {
    setShowLogoutPopup(false); // Close the popup without logging out
  };

  // If not authenticated, do not render the sidebar
  if (!isAuthenticated) {
    return null;  // Return null to hide the sidebar if not authenticated
  }

  return (
    <div className="sidebar">
      <h2>PBF GYM</h2>
      <ul>
        <li><Link to="/">Dashboard</Link></li>
        <li><Link to="/manage-users">Manage Users</Link></li>
        <li><Link to="/registered-users">Registered Users</Link></li>
        <li><Link to="/payments">Payments</Link></li>
        <li><Link to="/plans">Plans</Link></li>
        <li>
          <button className="logout-button" onClick={handleLogoutClick}>
            Logout
          </button>
        </li>
      </ul>

      {showLogoutPopup && (
        <div className="confirmation-overlay">
          <div className="confirmation-dialog">
            <p>Do you want to logout?</p>
            <button onClick={confirmLogout}>Yes</button>
            <button onClick={cancelLogout}>No</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
