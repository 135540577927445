import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Plans.css';

function Plans() {
  const [formData, setFormData] = useState({
    name: '',
    duration_months: '',
    fee_amount: '',
    discount: '',
  });
  const [plans, setPlans] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);

  // Fetch all plans when the component is mounted
  const fetchPlans = async () => {
    try {
      const response = await axios.get('http://38.242.142.120:5000/plans');
      setPlans(response.data); // Update plans with the fetched data
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  // Call fetchPlans when the component is mounted
  useEffect(() => {
    fetchPlans();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = 'Plan Name is required';
    }

    if (!formData.duration_months || isNaN(formData.duration_months) || formData.duration_months <= 0) {
      newErrors.duration_months = 'Plan Duration must be a positive number';
    }

    if (!formData.fee_amount || isNaN(formData.fee_amount) || formData.fee_amount <= 0) {
      newErrors.fee_amount = 'Plan Fee must be a positive number';
    }

    if (!formData.discount || isNaN(formData.discount) || formData.discount < 0 || formData.discount > 100) {
      newErrors.discount = 'Plan Discount must be a number between 0 and 100';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
          // Assuming your token is stored in localStorage (adjust as per your token storage method)
    const token = localStorage.getItem('token');

    const response = await axios.post(
      'http://38.242.142.120:5000/add_plan', 
      formData, 
      {
        headers: {
          'Authorization': token  // Add the token here
        }
      }
    );

    setSuccessMessage(`Plan "${response.data.name}" added successfully!`);

    // Clear the form data
    setFormData({
      name: '',
      duration_months: '',
      fee_amount: '',
      discount: '',
    });

    setErrors({});
    setShowDialog(false);

    // Refresh the plans list after adding a new plan
    fetchPlans();

    } catch (error) {
      console.error('Error adding plan:', error);
      setSuccessMessage('Error adding plan.');
    } finally {
      setLoading(false);
    }
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setFormData({
      name: '',
      duration_months: '',
      fee_amount: '',
      discount: '',
    });
    setErrors({});
  };

  const handleDeleteClick = (planId) => {
    setPlanToDelete(planId);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const token = localStorage.getItem('token');

      // Make API call to delete the plan
      await axios.delete(
        `http://38.242.142.120:5000/delete_plan/${planToDelete}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      
      setSuccessMessage(`Plan with ID ${planToDelete} deleted successfully!`);
      setShowDeleteConfirmation(false);
      
      // Refresh the plans list after deletion
      fetchPlans();
      
    } catch (error) {
      console.error('Error deleting plan:', error);
      setSuccessMessage('Error deleting plan.');
      setShowDeleteConfirmation(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setPlanToDelete(null);
  };

  return (
    <div className="plans-container">
      <h1 className="plans-title">Manage Plans</h1>

      {/* Display existing plans */}
      <div className="plans-list">
        <h2>Existing Plans</h2>
        <table className="plans-table">
          <thead>
            <tr>
              <th>Plan Name</th>
              <th>Duration (Months)</th>
              <th>Fee (₹)</th>
              <th>Discount (%)</th>
              <th>Actions</th> {/* New Actions column */}
            </tr>
          </thead>
          <tbody>
            {plans.length > 0 ? (
              plans.map((plan) => (
                <tr key={plan.id}>
                  <td>{plan.name}</td>
                  <td>{plan.duration_months}</td> {/* Duration in months */}
                  <td>{plan.fee_amount}</td> {/* Fee Amount */}
                  <td>{plan.discount}</td> {/* Discount */}
                  <td>
                    {/* Delete button for each plan with updated styling */}
                    <button
                      className="delete-btn"
                      onClick={() => handleDeleteClick(plan.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No plans available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Button to open add plan dialog */}
      <button className="add-new-plan-btn" onClick={openDialog}>
        Add New Plan
      </button>

      {/* Success message */}
      {successMessage && <p className="success-message">{successMessage}</p>}

      {/* Dialog for adding a new plan */}
      {showDialog && (
        <div className="plan-dialog">
          <div className="dialog-overlay" onClick={closeDialog}></div>
          <div className="dialog-content">
            <h2>Add New Plan</h2>
            <form onSubmit={handleSubmit} className="add-plan-form">
              <div className="form-group">
                <label>Plan Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="error-message">{errors.name}</p>}
              </div>

              <div className="form-group">
                <label>Plan Duration (in months)</label>
                <input
                  type="number"
                  name="duration_months"
                  value={formData.duration_months}
                  onChange={handleChange}
                  required
                />
                {errors.duration_months && <p className="error-message">{errors.duration_months}</p>}
              </div>

              <div className="form-group">
                <label>Plan Fee (₹)</label>
                <input
                  type="number"
                  name="fee_amount"
                  value={formData.fee_amount}
                  onChange={handleChange}
                  required
                />
                {errors.fee_amount && <p className="error-message">{errors.fee_amount}</p>}
              </div>

              <div className="form-group">
                <label>Plan Discount (%)</label>
                <input
                  type="number"
                  name="discount"
                  value={formData.discount}
                  onChange={handleChange}
                  required
                  max="100"
                  min="0"
                />
                {errors.discount && <p className="error-message">{errors.discount}</p>}
              </div>

              <div className="dialog-actions">
                <button type="submit" className="add-plan-btn" disabled={loading}>
                  {loading ? 'Adding Plan...' : 'Add Plan'}
                </button>
                <button type="button" className="cancel-btn" onClick={closeDialog}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete confirmation dialog */}
      {showDeleteConfirmation && (
        <div className="plan-dialog">
          <div className="dialog-overlay" onClick={handleCancelDelete}></div>
          <div className="dialog-content">
            <h2>Are you sure you want to delete this plan?</h2>
            <div className="dialog-actions">
              <button className="add-plan-btn" onClick={handleConfirmDelete}>
                Yes
              </button>
              <button className="cancel-btn" onClick={handleCancelDelete}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Plans;
