import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/RegisterUser.css';
import imageCompression from 'browser-image-compression';


function RegisterUser() {
  const [formData, setFormData] = useState({
    name: '',
    mobile_number: '',
    password: 'PBF_12345678', // Default password autofill
    plan_id: '', // Default plan will be handled in the select
    village: '',
    profile_pic: null,
    join_date: ''
  });
  const [plans, setPlans] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Ref for the profile picture input element
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get('http://38.242.142.120:5000/plans');
        setPlans(response.data);
        
        // Set the first plan as the default if plans are available
        if (response.data.length > 0) {
          setFormData(prevState => ({
            ...prevState,
            plan_id: response.data[0].id // Set the first plan as default
          }));
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };
    fetchPlans();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Compression options
        const options = {
          maxSizeMB: 1, // Maximum size in MB
          maxWidthOrHeight: 1024, // Maximum width or height
          useWebWorker: true, // Use a web worker for compression
        };
  
        // Compress the image
        const compressedFile = await imageCompression(file, options);
  
        // Update the form data with the compressed file
        setFormData({
          ...formData,
          profile_pic: compressedFile,
        });
  
        console.log('Original file size:', file.size / 1024, 'KB');
        console.log('Compressed file size:', compressedFile.size / 1024, 'KB');
      } catch (error) {
        console.error('Error compressing file:', error);
      }
    }
  };
  

  const validateForm = () => {
    const newErrors = {};
    const mobileRegex = /^[0-9]{10}$/;
    const passwordRegex = /.{10,}/; // At least 10 characters

    if (!formData.name) {
      newErrors.name = 'Name is required';
    }

    if (!formData.mobile_number || !mobileRegex.test(formData.mobile_number)) {
      newErrors.mobile_number = 'Mobile number must be 10 digits';
    }

    if (!formData.join_date) {
      newErrors.join_date = 'Join Date is required';
    } else {
      const today = new Date();
      const joinDate = new Date(formData.join_date);
      if (joinDate > today) {
        newErrors.join_date = 'Join Date cannot be in the future';
      }
    }

    if (!formData.password || !passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 10 characters';
    }

    if (!formData.plan_id) {
      newErrors.plan_id = 'Please select a plan';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const registrationData = new FormData();

    // Append all form data to FormData
    for (const key in formData) {
      registrationData.append(key, formData[key]);
    }

    const joinDate = new Date(formData.join_date);
    const day = String(joinDate.getDate()).padStart(2, '0');
    const month = String(joinDate.getMonth() + 1).padStart(2, '0');
    const year = joinDate.getFullYear();

    const formattedJoinDate = `${day}/${month}/${year}`;
    registrationData.append('due_date', formattedJoinDate);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('http://38.242.142.120:5000/register_user', registrationData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token,
        }
      });

      setSuccessMessage(`User ${response.data.name} registered successfully!`);

      // Reset the form and profile picture input after successful registration
      setFormData({
        name: '',
        mobile_number: '',
        password: 'PBF_12345678', // Keep the default password
        plan_id: response.data.plan_id || plans[0]?.id || '', // Keep the selected plan or default to first plan
        village: '',
        profile_pic: null, // Keep profile_pic as null
        join_date: ''
      });

      // Reset the file input using ref
      fileInputRef.current.value = null;

      setErrors({});
    } catch (error) {
      console.error("Error registering user:", error);
      setSuccessMessage("Error registering user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-user-container">
      <h1 className="register-title">Register a New User</h1>
      <form onSubmit={handleSubmit} className="register-form">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {errors.name && <p className="error-message">{errors.name}</p>}
        </div>

        <div className="form-group">
          <label>Mobile Number</label>
          <input
            type="tel"
            name="mobile_number"
            value={formData.mobile_number}
            onChange={handleChange}
            required
            maxLength="10"
          />
          {errors.mobile_number && <p className="error-message">{errors.mobile_number}</p>}
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>

        <div className="form-group">
          <label>Plan</label>
          <select
            name="plan_id"
            value={formData.plan_id}
            onChange={handleChange}
            required
          >
            <option value="">Select Plan</option>
            {plans.map(plan => (
              <option key={plan.id} value={plan.id}>
                {plan.name}
              </option>
            ))}
          </select>
          {errors.plan_id && <p className="error-message">{errors.plan_id}</p>}
        </div>

        <div className="form-group">
          <label>Village</label>
          <input
            type="text"
            name="village"
            value={formData.village}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Profile Picture</label>
          <input
            type="file"
            name="profile_pic"
            onChange={handleFileChange}
            required
            ref={fileInputRef} // Add ref to the file input
          />
        </div>

        <div className="form-group">
          <label>Join Date</label>
          <input
            type="date"
            name="join_date"
            value={formData.join_date}
            onChange={handleChange}
            required
          />
          {errors.join_date && <p className="error-message">{errors.join_date}</p>}
        </div>

        <button type="submit" className="register-btn" disabled={loading}>
          {loading ? 'Registering...' : 'Register User'}
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
}

export default RegisterUser;
