import React, { useState, useEffect } from 'react';
import '../styles/ManageUsers.css';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [status, setStatus] = useState('');
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [dueDate, setDueDate] = useState('');
  const [plan, setPlan] = useState('');
  const [showDialog, setShowDialog] = useState(false); // New state for payment dialog
  const [receivedBy, setReceivedBy] = useState(''); // New state for receiv
  const [plans, setPlans] = useState([]);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);



  
  

  // Fetch users data from API based on the selected tab
  useEffect(() => {
    fetchUsersByStatus(activeTab);
  }, [activeTab]);

  // Fetch users by the selected status (active, expired, feePending, all)
  const fetchUsersByStatus = (status) => {
    // Retrieve the token from local storage or state
    const token = localStorage.getItem('token'); // Or replace with state if needed
    
    const url = `http://38.242.142.120:5000/users_by_status/${status}`;
    
    // Add token to the headers
    const headers = {
      'Authorization':  token, // Pass token as a Bearer token
    };
  
    fetch(url, { headers: headers })
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error('Error fetching data:', error));
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based, so add 1)
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  

  const openImageModal = (user) => {
    setSelectedUser(user);
    setIsImageModalOpen(true);
  };

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };
  

  const openPlanModal = (user) => {
    setSelectedUser(user);
    console.log("Opening Plan Modal for User:", user);
  
    // Fetch available plans from the API
    fetch('http://38.242.142.120:5000/plans', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setPlans(data); // Set the fetched plans to state
          
          // Find the plan that matches the user's current plan_id
          const selectedPlan = data.find((plan) => plan.id === user.plan_id);
          
          if (selectedPlan) {
            setPlan(selectedPlan.id); // Set the plan ID as the selected plan
          } else {
            setPlan(''); // Reset if no matching plan is found
          }
  
          setIsPlanModalOpen(true); // Open the modal
        } else {
          alert('Error fetching plans');
        }
      })
      .catch((error) => {
        console.error('Error fetching plans:', error);
        alert('Something went wrong while fetching plans.');
      });
  };
  
  
  



  const closeModal = () => {
    setSelectedUser(null);
    setIsStatusModalOpen(false);
    setIsImageModalOpen(false);
    setIsDueDateModalOpen(false);
    setIsPlanModalOpen(false);
  };

  const openStatusModal = (user) => {
    setSelectedUser(user);
    setStatus(user.status); 
    setIsStatusModalOpen(true);
  };
  

  const openDueDateModal = (user) => {
    setSelectedUser(user);
    setDueDate(user.due_date || '');
    setIsDueDateModalOpen(true);
  };

  const handleUpdatePlan = () => {
    const token = localStorage.getItem('token');
    const url = `http://38.242.142.120:5000/update_plan/${selectedUser.gym_id}`; // URL with gym_id
  
    const requestBody = {
      gym_id: selectedUser.gym_id,
      plan: plan, // New plan selected in the modal
    };

    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(`Plan updated successfully.`);
          setIsPlanModalOpen(false);
          fetchUsersByStatus(activeTab);
        } else {
          alert(`Error updating plan: ${data.error?.message || 'Unknown error'}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(`Something went wrong. Please try again. Error: ${error.message}`);
      });
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleUpdateStatus = () => {
    const token = localStorage.getItem('token'); // Get token for authorization
    const url = `http://38.242.142.120:5000/update_status/${selectedUser.gym_id}`; // URL with gym_id
  
    // Construct the request body with new status
    const requestBody = {
      gym_id: selectedUser.gym_id,
      status: status, // New status selected in the modal
    };
  
    // Make API request to update status
    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': token, // Include token in headers
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(`User status updated to ${status}`);
          // Fetch updated users data after status change
          setIsStatusModalOpen(false);
          fetchUsersByStatus(activeTab);
        } else {
          alert(`Error updating status: ${data.error?.message || 'Unknown error'}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(`Something went wrong. Please try again. Error: ${error.message}`);
      });
  };
  

  const handleDueDateChange = (event) => {
    setDueDate(event.target.value);
  };

  const handleUpdateDueDate = () => {
    const token = localStorage.getItem('token'); // Get the token from localStorage
    const url = `http://38.242.142.120:5000/update_due_date/${selectedUser.gym_id}`; // Include gym_id in URL
  
    const requestBody = {
      gym_id: selectedUser.gym_id, // Pass the gym_id of the selected user
      due_date: dueDate, // New due date
    };
  
    // Make the API call to update the due date
    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': token, // Include the token in the Authorization header
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.error?.message || "Unknown error occurred");
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          alert(`Due date updated to ${dueDate}`);
         // Fetch the updated users list to reflect changes
         fetchUsersByStatus(activeTab);
  
         setIsDueDateModalOpen(false); // Close the dialog
        } else {
          alert(`Error updating due date: ${data.error?.message || "Unknown error"}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(`Something went wrong. Please try again. Error: ${error.message}`);
      });
  };
  
  

  const handleTabChange = (tab) => {
    setActiveTab(tab); // This will trigger the useEffect hook and fetch data for the selected tab
  };

  const handleReceivePayment = (user) => {
    setSelectedUser(user);
    setShowDialog(true); // Show the payment dialog
  };

  const handlePayment = () => {
    const paymentAmount = parseFloat(document.querySelector('.fee-input').value);
    const feeCollectorName = receivedBy;
  
    // Validate inputs
    if (isNaN(paymentAmount) || paymentAmount <= 0) {
      alert("Please enter a valid payment amount.");
      return;
    }
    if (!feeCollectorName) {
      alert("Fee collector name is required.");
      return;
    }
  
    const token = localStorage.getItem('token');
    if (!token) {
      alert("Token missing. Please log in.");
      return;
    }
  
    const requestBody = {
      gym_id: selectedUser.gym_id,
      payment_amount: paymentAmount,
      fee_collector_name: feeCollectorName,
    };
  
    setIsProcessingPayment(true); // Disable the button during API call
  
    fetch('http://38.242.142.120:5000/make_payment', {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Payment processed successfully') {
          alert(`Payment of ₹${paymentAmount} successfully processed.`);
          fetchUsersByStatus(activeTab);
          setShowDialog(false); // Close the dialog
        } else {
          alert('Error processing payment: ' + (data.error || 'Unknown error.'));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Something went wrong. Please try again.');
      })
      .finally(() => {
        setIsProcessingPayment(false); // Re-enable the button
      });
  };
  
  
  
  

  return (
    <div className="manage-users-container">
      <div className="user-tabs">
        <button className={activeTab === 'all' ? 'tab tab-active' : 'tab'} onClick={() => handleTabChange('all')}>All Users</button>
        <button className={activeTab === 'feePending' ? 'tab tab-active' : 'tab'} onClick={() => handleTabChange('feePending')}>Fee Pending</button>
        <button className={activeTab === 'expired' ? 'tab tab-active' : 'tab'} onClick={() => handleTabChange('expired')}>Expired</button>
      </div>
  
      <table className="user-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Gym ID</th>
            <th>Last Payment</th>
            <th>Fee Status</th>
            <th>Due Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.gym_id}>
              <td>
                <img 
                  src={`http://38.242.142.120:5000/profile_pics/${user.profile_pic}?token=${localStorage.getItem('token')}`} 
                  alt="User" 
                  className="user-avatar" 
                  onClick={() => openImageModal(user)} 
                />
              </td>
              <td><b>{user.name}</b></td>
              <td>{user.gym_id}</td>
              <td>{user.last_payment_date ? formatDate(user.last_payment_date) : 'Not Set'}</td>
              <td>
                            {user.due_amount > 0 ? (
                  <span className="fee-status red">₹{user.due_amount}</span>
                ) : user.due_amount < 0 ? (
                  <span className="fee-status green">+₹{Math.abs(user.due_amount)}</span>
                ) : (
                  <span className="fee-status green">Paid</span>
                )}
              </td>
              <td><b>{user.due_date ? formatDate(user.due_date) : 'Not Set'}</b></td>
              <td className="action-buttons">
                <div className="top-buttons">
                  <button className="action-btn payment-btn" onClick={() => handleReceivePayment(user)}>Receive Payment</button>
                  <button className="action-btn due-date-btn" onClick={() => openDueDateModal(user)}>Set Due Date</button>
                </div>
                <div className="bottom-buttons">
                  <button className="action-btn status-btn" onClick={() => openStatusModal(user)}>Change Status</button>
                  <button className="action-btn plan-btn" onClick={() => openPlanModal(user)}>Change Plan</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  
{/* Payment Dialog */}
      {/* Payment Dialog */}
      {showDialog && selectedUser && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-header">
              <h2>Payment for {selectedUser.name}</h2>
              <button className="close-btn" onClick={closeModal}>
                X
              </button>
            </div>

            <img
              src={`http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token')}`}
              alt={selectedUser.name}
              className="dialog-image"
            />
            <p><strong>Gym ID:</strong> {selectedUser.gym_id}</p>
            <p><strong>Mobile:</strong> {selectedUser.mobile_number}</p>
            <p><strong>Due Amount:</strong> <span style={{ color: selectedUser.due_amount > 0 ? 'red' : 'black' }}>₹{selectedUser.due_amount}</span></p>
            <p><strong>Due Date:</strong> {selectedUser.due_date}</p>
            <p><strong>Village:</strong> {selectedUser.village}</p>

            <input type="number" placeholder="Enter Amount" className="fee-input" />

            <label htmlFor="receivedBy" className="received-by-label">Received By:</label>
            <select
              id="receivedBy"
              className="received-by-select"
              value={receivedBy}
              onChange={(e) => setReceivedBy(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Labbi">Labbi</option>
              <option value="Tari">Tari</option>
            </select>

            <div className="payment-buttons">
            <button 
              onClick={handlePayment} 
              disabled={isProcessingPayment} 
              className={`payment-btn ${isProcessingPayment ? 'disabled' : ''}`}>
              {isProcessingPayment ? 'Processing...' : 'Mark as Paid'}
            </button>
              <button onClick={closeModal} className="close-btn">×</button>
            </div>
          </div>
        </div>
      )}

  
      {/* Image Modal */}
      {isImageModalOpen && (
        <div className="image-modal-overlay" onClick={closeModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>X</button>
            <img 
              src={`http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token')}`} 
              alt="User" 
              className="image-modal-avatar" 
            />
          </div>
        </div>
      )}
  
      {/* Status Modal */}
      {isStatusModalOpen && (
        <div className="status-modal-overlay" onClick={closeModal}>
          <div className="status-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>X</button>
            <div className="status-modal-header">
              <img 
                src={`http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token')}`} 
                alt="User" 
                className="status-modal-avatar" 
              />
              <h2>{selectedUser.name}</h2>
            </div>
            <div className="status-modal-body">
              <p>Gym ID: {selectedUser.gym_id}</p>
              <p>Last Payment: {selectedUser.last_payment_date}</p>
              <div className="status-select-container">
                <label className="status-label">Fee Status:</label>
                <div className="status-options">
                  <label className="radio-btn">
                    <input 
                      type="radio" 
                      value="Active" 
                      checked={status === 'Active'} 
                      onChange={handleStatusChange} 
                    />
                    Active
                  </label>
                  <label className="radio-btn">
                    <input 
                      type="radio" 
                      value="Expired" 
                      checked={status === 'Expired'} 
                      onChange={handleStatusChange} 
                    />
                    Expired
                  </label>
                </div>
              </div>
            </div>
            <div className="status-modal-footer">
              <button className="update-btn" onClick={handleUpdateStatus}>Update</button>
            </div>
          </div>
        </div>
      )}
  
      {/* Due Date Modal */}
      {isDueDateModalOpen && (
        <div className="status-modal-overlay" onClick={closeModal}>
          <div className="status-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>X</button>
            <div className="status-modal-header">
              <img 
                src={`http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token')}`} 
                alt="User" 
                className="status-modal-avatar" 
              />
              <h2>{selectedUser.name}</h2>
            </div>
            <div className="status-modal-body">
              <label>Due Date:</label>
              <input
                type="date"
                value={dueDate}
                onChange={handleDueDateChange}
              />
            </div>
            <div className="status-modal-footer">
              <button className="update-btn" onClick={handleUpdateDueDate}>Update</button>
            </div>
          </div>
        </div>
      )}
  
{/* Plan Modal */}
{isPlanModalOpen && (
  <div className="status-modal-overlay" onClick={closeModal}>
    <div className="status-modal-content" onClick={(e) => e.stopPropagation()}>
      <button className="close-btn" onClick={closeModal}>X</button>

      <div className="status-modal-header">
        <img 
          src={
            selectedUser?.profile_pic 
              ? `http://38.242.142.120:5000/profile_pics/${selectedUser.profile_pic}?token=${localStorage.getItem('token') || ''}` 
              : '/default-avatar.png'
          } 
          alt="User" 
          className="status-modal-avatar" 
        />
        <h2>{selectedUser?.name || 'User Name'}</h2>
      </div>

      <div className="status-modal-body">
        <label htmlFor="plan-select">Plan:</label>
        <select value={plan} onChange={handlePlanChange}>
      <option value="" disabled>Select a Plan</option>
      {plans.map((planOption) => (
        <option key={planOption.id} value={planOption.id}>
          {planOption.name}
        </option>
      ))}
    </select>
      </div>

      <div className="status-modal-footer">
        <button 
          className="update-btn" 
          onClick={handleUpdatePlan} 
          disabled={!plan}
        >
          Update
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
  
};

export default ManageUsers;
